import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

import Box from "components/layout/Box";

import {
  ListBulletStyled, MyTitle, SectionWrapper, TitleStyled
} from "views/PresentationPage/Sections/styled";
import { Card1 } from "views/Cards/Cards";
import { services } from "views/dataServices"

import { Text1 } from "components/core/Typography/text";

const useStyles = makeStyles(officeStyle);



export default function SectionServices() {
  const classes = useStyles();

  return (
    <SectionWrapper>
      <Box>
        <TitleStyled>
          Qué ofrece AlianZas
        </TitleStyled>
      </Box>
      <Box
        split={12}
        columns={services.map(({ title, src, items }, i) => (
            <Card1 key={i} classes={classes} src={src}>
              <MyTitle>{ title }</MyTitle>
              <ListBulletStyled number={items.length > 1}>
                {items.map(({ label, list }, n) => (
                  <li key={n}>
                    <Text1>{label}</Text1>
                    { list && 
                      <ul>{ list.map(o=> <li key={o}><Text1>{o}</Text1></li>)}</ul>}
                  </li>))}
              </ListBulletStyled>
            </Card1>
          ))}>
      </Box>
    </SectionWrapper>
  );
}



/*
const Card3 = ({ classes, src, children, label = null }) => (
  <CardBackgroundStyled src={src}>
    <CardHeader image>
      <a href="#pablo" onClick={e => e.preventDefault()}>
        <img src={cardProfile4} alt="..." />
        <div className={classes.cardTitleAbsolute}>
          Tania Andrew
        </div>
      </a>
      <div
        className={classes.coloredShadow}
        style={{
          backgroundImage: `url(${cardProfile4})`,
          opacity: "1"
        }}
      />
    </CardHeader>
    <CardBody>
      <Info>
        <h6 className={classes.cardCategory}>WEB DESIGNER</h6>
      </Info>
      <p className={classes.cardDescription}>
        Don{"'"}t be scared of the truth because we need to
        restart the human foundation in truth And I love you
        like Kanye loves Kanye I love Rick Owens’ bed design but
        the back is...
      </p>
    </CardBody>
    <CardFooter
      profile
      className={classes.justifyContentCenter}
    >
      <Button justIcon simple color="twitter">
        <i className="fab fa-twitter" />
      </Button>
      <Button justIcon simple color="dribbble">
        <i className="fab fa-dribbble" />
      </Button>
      <Button justIcon simple color="instagram">
        <i className="fab fa-instagram" />
      </Button>
    </CardFooter>
  </CardBackgroundStyled>
)
*/