import images from "components/styles/images";

const services = [
  {
    title: 'Proyectos sociales',
    src: images.bg.social,
    srcLow: images.bg.socialLow,
    items: [
      { label: 'Locales, autonómicos, estatales y europeos' },
      { label: 'Entidades públicas y privadas' },
      { label: 'Apoyo, realización y supervisión de todo el proceso',
        list: [
          'Diagnóstico',
          'Elaboración',
          'Evaluación continua, final',
          'Presentaciones en jornadas o publicación'
        ]}
      ]
  },{
    title: 'Gestión Equipo, apoyo a entidades',
    src: images.bg.people,
    srcLow: images.bg.peopleLow,
    items: [
      { label: 'Coordinación' },
      { label: 'Cohesión y crecimiento como equipo' },
      { label: 'Planificaciones estratégicas' },
      { label: 'Resolución de conflictos' },
      { label: 'Metodologías Ágiles' },
      { label: 'Puesta en marcha de entidades del tercer sector' }
    ]
  },{
    title: 'Formación en Igualdad y prevención de Violencia de Género',
    src: images.bg.handsPurple,
    srcLow: images.bg.handsPurpleLow,
    items: [
      { label: 'Dirigidas y adaptadas a profesionales de todos los ámbitos' },
      { label: 'Dirigidas a población infantil, con acciones específicas para su nivel de desarrollo' },
      { label: 'Dirigidas a población juvenil, con acciones dirigidas especialmente a este grupo, respetando sus formas de comunicación y su toma de decisiones' },
      { label: 'On line y presenciales' },
      { label: 'Planificación, ejecución y evaluación de acciones para el Pacto de Estado contra la Violencia de Género, para la Dirección General de Igualdad y cualquier otra institución' }
    ]
  },{
    title: 'Apoyo y Asesoramiento en la Empresa',
    src: images.bg.equal,
    srcLow: images.bg.equalLow,
    items: [
      { label: 'Planes de Igualdad' },
      { label: 'Protocolos para la prevención y actuación frente al acoso sexual y el acoso por razón de sexo' },
      { label: 'Distintivo de “Igualdad en la Empresa”' },
      { label: 'Registro Retributivo' },
      { label: 'Búsqueda, Información y solicitud de subvenciones para la Elaboración e Implementación de Planes de Igualdad' },
      { label: 'Dirigido a entidades públicas y privadas',
        list: [
          'Municipales',
          'Dirigido a las personas empleadas'
        ] },
      { label: 'Formación en Igualdad' },
      { label: 'Registro Salarial' },
      { label: 'Diagnóstico' },
      { label: 'Elaboración' },
      { label: 'Evaluación' }
    ]
    
  },{
    title: 'Estrategias de desarrollo local y comunitaria',
    src: images.bg.plan,
    srcLow: images.bg.planLow,
    items: [
      { label: 'Especial atención al desarrollo rural' },
      { label: 'Planes Locales de Igualdad' }
    ]
  },{
    title: 'Puesta en marcha de acciones para desarrollar proyectos ya implementados',
    src: images.bg.start,
    srcLow: images.bg.startLow,
    items: [
      { label: 'Acciones sociales y de desarrollo comunitario' }
    ]
  },{
    title: 'Responsabilidad Social Corporativa',
    src: images.bg.rope,
    srcLow: images.bg.ropeLow,
    items: [
      { label: 'Implementación en empresas' },
      { label: 'Gestión de proyectos de RSC'},
      { label: 'Difusión de la RSC' },
      { label: 'Eventos de promoción de la RSC' }
    ]
  },{
    title: 'Desarrollo personal y laboral',
    src: images.bg.coffee,
    srcLow: images.bg.coffeeLow,
    items: [
      { label: 'Itinerarios personalizados de inserción' },
      { label: 'Planificación Estratégica hacia nuevas oportunidades laborales o de ascenso'},
      { label: 'Entrenamiento de habilidades sociales y comunicativas' },
      { label: 'Grupos de desarrollo personal' }
    ]
  }
]

export {
  services,
}